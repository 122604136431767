@import '../../../../scss/theme-bootstrap';

// Sign-in Form. This applies to both the gnav dropdown and the standalone
// perlgem page.
.signin-block {
  font-size: 14px;
  width: 100%;
  text-align: $ldirection;
  max-width: 749px;
  margin: 0 auto;
  position: relative;
  .error_messages {
    margin: 0 0 10px;
  }
  .elc-user-state-logged-in & {
    padding: 0;
    @include breakpoint($landscape-up) {
      padding: 1em 0 0;
      position: relative;
    }
  }
  &__close {
    position: absolute;
    #{$rdirection}: 0;
    font-size: 20px;
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
    .icon--close {
      width: 20px;
      height: 20px;
    }
  }
  &__forms {
    margin: 0 0 10px;
    .elc-user-state-logged-in & {
      display: none;
    }
    @include breakpoint($xlarge-up) {
      padding-top: 20px;
    }
  }
  &--signin {
    .signin-block__form--register {
      display: none;
    }
    .signin-block__form--signin {
      display: block;
    }
  }
  &__form--signin {
    display: none;
  }
  &__main,
  &__footer {
    padding: 0 2em;
    margin-top: 10px;
  }
  &__title {
    @include account_page_header;
    bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  &__subtitle {
    font-size: 1.5em;
    line-height: 1;
    text-align: center;
    display: none;
  }
  &--register__item-left {
    width: 49%;
    float: $ldirection;
    margin-#{$rdirection}: 1%;
    input.adpl--processed + label {
      margin-top: calc(-3.2em - 1px);
      font-size: 1.25em;
    }
  }
  &--register__item-right {
    width: 49%;
    float: $ldirection;
    input.adpl--processed + label {
      margin-top: calc(-3.2em - 1px);
      font-size: 1.25em;
    }
  }
  &__toggle-password {
    padding: 4px 0 9px;
    &__option {
      cursor: pointer;
      text-align: center;
      display: inline-block;
      &--hide {
        display: none;
      }
      .signin-block--show-password & {
        &--show {
          display: none;
        }
        &--hide {
          display: inline-block;
        }
      }
    }
  }
  &__forgot-password {
    color: $color-off-black;
    border-bottom: 1px solid $color-off-black;
    text-transform: uppercase;
    margin-bottom: 15px;
    &:hover {
      color: $color-off-black;
      text-decoration: none;
    }
  }
  &__submit {
    width: 100%;
    margin-top: 9px;
  }
  &__toggle {
    margin: 15px 0;
    text-transform: uppercase;
    &__cta {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__authed {
    @include pie-clearfix;
    display: none;
    @include breakpoint($landscape-up) {
      min-width: 370px;
      max-width: 749px;
      margin: 0 auto;
    }
    &__nav {
      @include breakpoint($landscape-up) {
        padding: 0 30px;
        float: $ldirection;
        width: 240px;
      }
      &__title {
        padding-#{$ldirection}: 20px;
        margin: 0;
        // Wait for js to render the title text as it's raw, user-entered
        // mustache.
        display: none;
        @include breakpoint($landscape-up) {
          font-size: 24px;
          padding: 0;
          line-height: 1;
          margin: 0 0 15px;
        }
        &--rendered {
          display: block;
        }
      }
      &__signout-link {
        font-size: 14px;
        border-top: solid 1px $color-off-black;
        border-bottom: solid 1px $color-off-black;
        display: block;
        padding-#{$ldirection}: 20px;
        @include breakpoint($landscape-up) {
          border: none;
          display: inline;
          padding: 0;
          line-height: 1;
        }
      }
    }
    &__content {
      @include breakpoint($landscape-up) {
        float: $rdirection;
        width: 240px;
        border-#{$ldirection}: solid 1px $color-light-gray;
        padding: 0 30px;
      }
    }
    .elc-user-state-logged-in & {
      display: block;
    }
  }
}

.signin-block {
  .signin-block__forms {
    .signin-block__field {
      display: block;
      width: 100%;
      &--first-name,
      &--last-name {
        width: 49%;
      }
      &--first-name {
        float: $ldirection;
      }
      &--last-name {
        float: $rdirection;
      }
    }
    .signin-block__checkbox {
      label,
      .label {
        width: 90%;
      }
    }
    .pp_birth_day_gift {
      text-transform: none;
    }
    .pp_birth_day_container {
      .basic-responsive {
        width: 100%;
        display: inline-block;
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
    }
  }
  &.signin-overlay {
    .signin-block__forms {
      input[type='submit'] {
        float: $rdirection;
      }
    }
    .signin-block__checkbox__text {
      line-height: get-line-height(14px, 16px);
      p {
        display: inline;
      }
    }
    .signin-block__checkbox {
      &--terms,
      &--email-list,
      &--pure-priv {
        width: 90%;
        line-height: get-line-height(14px, 16px);
        margin-bottom: 10px;
        .label-content {
          line-height: get-line-height(14px, 16px);
        }
      }
      .label-content a {
        text-decoration: underline;
      }
    }
    .overlay-link {
      font-weight: bold;
    }
  }
}

// auth states visibility
.elc-user-state-anonymous {
  .hide_anon {
    display: none;
  }
}

.elc-user-state-logged-in {
  .hide_auth {
    display: none;
  }
}

.new-account__fieldset {
  .form_element.pp_birth_day_container {
    margin: 14px 0 8px;
    .birthday_month,
    .birthday_date {
      margin-#{$rdirection}: 5px;
      @include breakpoint($med-small-down) {
        width: 100%;
        margin: 10px 0;
      }
      select {
        @include breakpoint($med-small-down) {
          width: 100%;
        }
      }
    }
  }
}

.sign-in {
  .registration {
    display: none;
  }
  .account-links {
    display: none;
  }
}

.social-login {
  &__container {
    text-align: center;
    padding: 20px 0;
  }
  &__divider {
    @include h13;
    width: 78%;
    @include breakpoint($mobile-portrait) {
      width: 100%;
    }
    margin: 15px auto 0;
    margin-bottom: 22px;
  }
  &__terms {
    margin-top: 15px;
    text-align: $ldirection;
    padding: 0 51px;
    @include breakpoint($mobile-portrait) {
      padding: 0;
    }
    width: 100%;
    p {
      margin: 0 7px;
      text-transform: uppercase;
    }
    a {
      text-decoration: underline;
    }
  }
  &__title,
  &__info {
    display: none;
  }
  &__email-opt-in {
    padding-top: 21px;
    display: flex;
  }
  &__opt-in-label {
    font-weight: normal;
  }
}
/* Checkout section */
.checkout-panel {
  .social-login {
    &__divider {
      width: 100%;
    }
    &__terms {
      width: 100%;
      padding: 0 12px;
      @include breakpoint($medium-up) {
        padding: 0;
      }
      p {
        margin: 0;
        text-align: $ldirection;
      }
    }
    &__email-opt-in {
      padding-#{$ldirection}: 10px;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 0;
      }
    }
    &__opt-in-label {
      margin-#{$rdirection}: 29px;
      text-align: $ldirection;
    }
  }
}
/* Signin overlay */
.signin-overlay {
  .social-login {
    &__divider {
      width: 91%;
    }
    &__terms {
      width: 100%;
      padding-#{$ldirection}: 28px;
      text-transform: uppercase;
      font-weight: lighter;
    }
    &__email-opt-in {
      padding-#{$ldirection}: 28px;
    }
  }
}
/* Account sign in page */
.sign-in-page {
  .social-login {
    &__container {
      padding-#{$ldirection}: 0;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 54px;
      }
    }
    &__terms {
      padding: 0;
      @include breakpoint($medium-up) {
        padding: 0 70px;
      }
      p {
        margin: 0;
        @include breakpoint($medium-up) {
          margin: 0 7px;
        }
      }
    }
    &__email-opt-in {
      text-align: $ldirection;
      padding-#{$ldirection}: 0;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 77px;
      }
      .social-login__opt-in-checkbox {
        width: 13%;
      }
    }
    &__opt-in-label {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 83%;
      }
    }
  }
}
/* Account profile preference */
.profile-page {
  .social-info {
    margin-#{$ldirection}: 0;
    border-top: 1px solid $light-gray;
    margin-top: 20px;
    @include breakpoint($landscape-up) {
      margin-#{$ldirection}: 27%;
    }
    &__header {
      font-size: 20px;
      font-weight: bold;
      padding-top: 6px;
      @include breakpoint($landscape-up) {
        font-size: 28px;
        font-weight: normal;
      }
      @include breakpoint($mobile-portrait) {
        font-size: 20px;
        font-weight: bold;
      }
      text-transform: uppercase;
    }
    &__connect {
      padding: 20px 0 25px;
    }
  }
}
/* GNAV styling */
.site-utils__dropdown__content {
  .social-login {
    &__container {
      @include swap_direction(padding, 22px 28px 0 24px);
    }
  }
}
/* Disconnect button styling */
#facebook_disconnect {
  display: inline;
  padding-#{$ldirection}: 20px;
  input.form-submit {
    height: 20px;
    margin-bottom: 4px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;
  }
}
/* Order confirmation page */
.checkout--confirmation-page {
  .social-login {
    padding-#{$ldirection}: 0;
    @include breakpoint($medium-down) {
      padding-#{$ldirection}: 10px;
    }
    border-top: 1px solid $light-gray;
    &__container {
      text-align: $ldirection;
      padding-bottom: 0;
      @include breakpoint($mobile-portrait) {
        padding-bottom: 20px;
      }
      @include breakpoint($portrait-up) {
        padding-bottom: 20px;
      }
    }
    &__terms,
    &__divider {
      display: none;
    }
    &__title {
      display: block;
      text-transform: uppercase;
      font-size: 19px;
    }
    &__info {
      margin-bottom: 10px;
      display: block;
      margin-top: 10px;
    }
    &__opt-in-label {
      text-transform: unset;
    }
  }
}

.social-login-style {
  clear: both;
}

.gnav {
  .social-login {
    &__divider {
      .inner {
        background: $white;
      }
    }
  }
}
/* Appointment Page */
.booking-step {
  .social-login {
    &__opt-in-label {
      text-transform: unset;
      text-align: $ldirection;
    }
    &__terms {
      @include breakpoint($medium-up) {
        padding: 0;
      }
    }
  }
}
